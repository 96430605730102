/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/index.scss"

// Don't scroll back to the last known scrolling positions on navigation
// @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
export const shouldUpdateScroll = () => false
